export default function Cwitch({ icon, name, checked, handler, disabled }) {
  return (
    <label className="cp-cwitch">
      <input
        type="checkbox"
        checked={checked[name]}
        disabled={disabled}
        onChange={() =>
          handler((s) => {
            return { ...s, [name]: !checked[name] };
          })
        }
      />
      <div className="cp-cwitch-style">
        <i className={`icon-${icon}`}></i>
      </div>
    </label>
  );
}
