export default function Switch({ on, off, name, checked, handler, disabled }) {
  return (
    <label className="cp-switch">
      <div className="cp-switch-group">
        <input
          type="checkbox"
          checked={checked[name]}
          disabled={disabled}
          onChange={() =>
            handler((s) => {
              return { ...s, [name]: !checked[name] };
            })
          }
        />
        <span className="cp-switch-style"></span>
        <span className="cp-label-left">{on}</span>
        <span className="cp-label-right">{off}</span>
      </div>
    </label>
  );
}
