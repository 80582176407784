import { defaultUnit, colors } from "../common/constant";
import { toast } from "react-toastify";

const GET_ITEMS = "GET_ITEMS";
const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";
const GET_ITEMS_ERROR = "GET_ITEMS_ERROR";
const SET_ITEMS = "SET_ITEMS";
const SET_NAME = "SET_NAME";
const ADD_TEXT = "ADD_TEXT";
const ADD_SVG = "ADD_SVG";
const EVENT_END = "EVENT_END";
const REMOVE_ITEM = "REMOVE_ITEM";
const CHANGE_COLOR = "CHANGE_COLOR";
const CHANGE_FONT = "CHANGE_FONT";
const CHANGE_SIZE = "CHANGE_SIZE";
const CHANGE_RESIZE = "CHANGE_RESIZE";
const CHANGE_BACKING = "CHANGE_BACKING";
const CHANGE_BACKING_COLOR = "CHANGE_BACKING_COLOR";
const CHANGE_LOCATION = "CHANGE_LOCATION";
const CHANGE_ADAPTER = "CHANGE_ADAPTER";
const CHANGE_GROUP_RESIZE = "CHANGE_GROUP_RESIZE";

export const getItems = () => async (dispatch) => {
  dispatch({ type: GET_ITEMS });
  try {
    //   const result = await api.apiGetRateBlock();
    //   if (result.data.stt === "err") {
    //     toast.warn(result.data.msg);
    //     dispatch({type: GET_ITEMS_SUCCESS, dtc: {}, data: []});
    //   } else {
    //     dispatch({type: SET_CONFIG, config: result.data.res.etc});
    //     dispatch({type: SET_CHANNELS, channels: result.data.res.channels});
    // dispatch({type: GET_ITEMS_SUCCESS, data: result.data.res.data});

    dispatch({ type: GET_ITEMS_SUCCESS, data: [...defaultUnit] });
    //   }
  } catch (e) {
    dispatch({ type: GET_ITEMS_ERROR, error: e });
  }
};

export const setItems = (data) => async (dispatch) => {
  dispatch({ type: SET_ITEMS, data: data });
};

export const setName = (ref, id, name) => (dispatch) => {
  dispatch({ type: SET_NAME, ref, id, name });
};

export const addText = () => (dispatch) => {
  dispatch({ type: ADD_TEXT });
};

export const addSvg = (i) => (dispatch) => {
  dispatch({ type: ADD_SVG, i });
};

export const eventEnd = (ref, id) => (dispatch) => {
  dispatch({ type: EVENT_END, ref, id });
};

export const removeItem = (id) => (dispatch) => {
  dispatch({ type: REMOVE_ITEM, id });
};

export const changeColor = (e, id) => (dispatch) => {
  dispatch({ type: CHANGE_COLOR, e, id });
};

export const changeSize = (v) => (dispatch) => {
  dispatch({ type: CHANGE_SIZE, v });
};

export const changeFont = (e, ref, id) => (dispatch) => {
  dispatch({ type: CHANGE_FONT, e, ref, id });
};

export const changeReSize = (ref, id) => (dispatch) => {
  dispatch({ type: CHANGE_RESIZE, ref, id });
};

export const changeBacking = (id) => (dispatch) => {
  dispatch({ type: CHANGE_BACKING, id });
};

export const changeBackingColor = (id) => (dispatch) => {
  dispatch({ type: CHANGE_BACKING_COLOR, id });
};

export const changeLocation = (e) => (dispatch) => {
  dispatch({ type: CHANGE_LOCATION, e });
};

export const changeAdapter = (e) => (dispatch) => {
  dispatch({ type: CHANGE_ADAPTER, e });
};

export const changeGroupReSize = (area) => (dispatch) => {
  dispatch({ type: CHANGE_GROUP_RESIZE, area });
};

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export default function syns(state = initialState, action) {
  const getItem = (id) => {
    return state.data.find((v) => {
      return v.id == id;
    });
  };

  const reSize = (ref, id) => {
    const item = getItem(id);
    const rect = ref.current[id];
    item.width = ((rect.offsetWidth / 7) * item.scale[0] + 3).toFixed(0) * 10;
    item.height = ((rect.offsetHeight / 7) * item.scale[1] + 3).toFixed(0) * 10;
    return item;
  };

  const getRandomColor = () => {
    let random = colors[Math.floor(Math.random() * (colors.length - 1))];
    let check = true;
    while (check) {
      check = state.data.find((v) => {
        return v.color === random;
      });
      if (check) {
        random = colors[Math.floor(Math.random() * (colors.length - 1))];
      }
    }
    return random;
  };

  const getNewId = () => {
    // 신규 임시 코드 생성
    const ids = state.data.map((v) => {
      if (v.id.substring(0, 3) !== "new") return;
      return v.id.substring(3);
    });
    const max = state.data.length > 0 ? Math.max.apply(null, ids) : 0;
    return "new" + (parseInt(max) + 1);
  };
  switch (action.type) {
    case GET_ITEMS:
      state = {
        data: [],
        loading: true,
        error: null,
      };
      break;
    case GET_ITEMS_SUCCESS:
      state = {
        data: action.data,
        loading: false,
        error: null,
      };
      break;
    case GET_ITEMS_ERROR:
      state = {
        data: [],
        loading: false,
        error: action.error,
      };
      break;
    case SET_ITEMS:
      state = {
        data: action.data,
        loading: false,
        error: null,
      };
      break;
    case SET_NAME:
      const item1 = reSize(action.ref, action.id);
      item1.name = action.name;
      state = {
        ...state,
        data: state.data.map((v) => {
          if (v.id == action.id) {
            return item1;
          } else {
            return {
              ...v,
            };
          }
        }),
      };
      break;

    case ADD_TEXT:
      const text_length = state.data.filter((v) => v.type === "text").length;
      if (text_length > 5) {
        toast.error("최대 다섯개임", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        state = {
          ...state,
          data: state.data.concat({
            ...state.data[0],
            id: getNewId(),
            name: "NEW NEON",
            color: getRandomColor(),
            width: 250,
            height: 100,
          }),
        };
      }
      break;
    case ADD_SVG:
      const svg_length = state.data.filter((v) => v.type === "svg").length;
      if (svg_length > 4) {
        toast.error("최대 다섯개임", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        state = {
          ...state,
          data: state.data.concat({
            ...state.data[0],
            type: "svg",
            font: "",
            name: action.i,
            id: getNewId(),
            color: getRandomColor(),
          }),
        };
      }
      break;
    case EVENT_END:
      const item2 = reSize(action.ref, action.id);
      state = {
        ...state,
        data: state.data.map((v) => {
          if (v.id == action.id) {
            return item2;
          } else {
            return {
              ...v,
            };
          }
        }),
      };
      break;
    case REMOVE_ITEM:
      state = {
        ...state,
        data: state.data.filter((v) => {
          return v.id !== action.id;
        }),
      };
      break;
    case CHANGE_COLOR:
      state = {
        ...state,
        data: state.data.map((v) => {
          if (v.id == action.id) {
            return {
              ...v,
              color: action.e.target.value,
            };
          } else {
            return {
              ...v,
            };
          }
        }),
      };
      break;
    case CHANGE_SIZE:
      state = {
        ...state,
        data: state.data.map((v) => {
          return {
            ...v,
            size: action.v,
          };
        }),
      };
      break;
    case CHANGE_FONT:
      const item3 = reSize(action.ref, action.id);
      item3.font = action.e.target.dataset.font;
      state = {
        ...state,
        data: state.data.map((v) => {
          if (v.id == action.id) {
            return item3;
          } else {
            return {
              ...v,
            };
          }
        }),
      };
      break;
    case CHANGE_RESIZE:
      const item4 = reSize(action.ref, action.id);
      state = {
        ...state,
        data: state.data.map((v) => {
          if (v.id == action.id) {
            return item4;
          } else {
            return {
              ...v,
            };
          }
        }),
      };
      break;
    case CHANGE_BACKING:
      state = {
        ...state,
        data: state.data.map((v) => {
          return {
            ...v,
            backing: action.id,
          };
        }),
      };
      break;
    case CHANGE_BACKING_COLOR:
      state = {
        ...state,
        data: state.data.map((v) => {
          return {
            ...v,
            backingColor: action.id,
          };
        }),
      };
      break;
    case CHANGE_LOCATION:
      state = {
        ...state,
        data: state.data.map((v) => {
          return {
            ...v,
            location: action.e.target.value,
          };
        }),
      };
      break;
    case CHANGE_ADAPTER:
      state = {
        ...state,
        data: state.data.map((v) => {
          return {
            ...v,
            adapter: action.e.target.value,
          };
        }),
      };
      break;
    case CHANGE_GROUP_RESIZE:
      state = {
        ...state,
        data: state.data.map((v) => {
          return {
            ...v,
            groupWidth: action.area.offsetWidth,
            groupHeight: action.area.offsetHeight,
          };
        }),
      };
      break;
  }
  return state;
}
