// 외부 라이브러리
import { useState, useRef, useEffect, Fragment } from "react";
import { flushSync } from "react-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import domtoimage from "dom-to-image";
import useLocalStorage from "../../hooks/useLocalStorage";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// 데이터 및 함수
import { colors, backingColors, fonts, sizes, backings, mobileMenu } from "../../common/constant";
import { comma, checkLength } from "../../common/functions";
import {
  getItems,
  setItems,
  addText,
  addSvg,
  removeItem,
  eventEnd,
  changeColor,
  changeSize,
  changeFont,
  changeReSize,
  changeBacking,
  changeBackingColor,
  changeGroupReSize,
} from "../../modules/dyns";

// 컴포넌트(외부 라이브러리 포함)
import Switch from "../atoms/Switch";
import Cwitch from "../atoms/Cwitch";
import Textarea from "../atoms/Textarea";
import Moveable from "react-moveable";
import hexToRgba from "hex-to-rgba";
import Slider from "react-slick";
import Svg from "../atoms/Svg";

function MobileEditor({ setLoader }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [action, setAction] = useState({
    active: false, // 유닛 active 상태여부
    neon: false, // 네온스위치
    light: false, // 백그라운드 라이트
    ruler: false, // 길이를 표현하는 자 표시 여부
    fliker: false, // 네온 깜박임
    move: false, // 이동시 투명처리
    group: false, // 그룹여부
    infoPop: "", // 인포팝업
    backPop: "", // 백킹팝업
    unitTab: "text", // 메뉴탭
  });
  const { dyns } = useSelector((state) => state); // 리덕스 데이터
  const dynsData = dyns.data;
  const [checkDrag, setCheckDrag] = useState(false);
  const [unitTarget, setUnitTarget] = useState(null); // moveable 대상 오브젝트
  const [unitData, setUnitData] = useState(null); // moveable 대상 데이터
  const [groupTarget, setGroupTarget] = useState(null); // group moveable 대상 오브젝트
  const [groupSize, setGroupSize] = useState({ width: 0, height: 0 });
  const [storage, setStorage] = useLocalStorage("dyns", { data: null, img: null });
  const [mobileTab, setMobileTab] = useState(mobileMenu[1]);
  const refContent = useRef(null); // 대지 엘리먼트 요소
  const refMoveable = useRef([]); // moveable 엘리먼트 요소
  const refSlider = useRef([]);
  const refItems = useRef([]); // unit 엘리먼트 요소

  useEffect(() => {
    // 스토리지 정보가 없으면 기본값으로 디스패치
    if (storage.data === null) {
      dispatch(getItems());
    } else if (storage.data.length === 1) {
      // 아무것도 없으면 초기화
      dispatch(getItems());
    } else {
      dispatch(setItems(storage.data));
    }
  }, []);

  // 현재 선택된 유닛아이디 설정
  useEffect(() => {
    if (unitTarget !== null) {
      setUnitData(
        dynsData.find((v) => {
          return v.id == (unitTarget === null ? "new1" : unitTarget.dataset.id);
        })
      );
    }
  }, [unitTarget, dynsData]);

  // 스토리지에 실시간 업데이트
  useEffect(() => {
    setStorage({ ...storage, data: dynsData });
  }, [dynsData]);

  // 그룹 클릭 시 설정
  useEffect(() => {
    if (action.group) {
      setUnitTarget(null);
      setUnitData(null);
      setMobileTab("BACKING");
      setAction((s) => {
        return { ...s, ruler: false, unitTab: "text" };
      });
      setGroupTarget([].slice.call(document.querySelectorAll(".unit")));
      setTimeout(() => {
        const area = document.querySelector(".moveable-area");
        dispatch(changeGroupReSize(area));
        setGroupSize({
          width: area.offsetWidth,
          height: area.offsetHeight,
        });
      }, 100);
    } else if (groupTarget !== null) {
      setGroupTarget(null);
      setMobileTab("FONT");
    }
  }, [action.group]);

  // 초기 사이즈 설정
  useEffect(() => {
    setTimeout(() => {
      dispatch(changeReSize(refItems, unitData?.id == null ? "new1" : unitData?.id));
    }, 100);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (refMoveable.current !== null) {
        setUnitTarget(null);
        setUnitData(null);
      }
    });
  }, []);

  const activeItem = (e) => {
    if (!action.group) {
      setUnitTarget(e.target.closest(".item_wrap"));
      setCheckDrag(false);
      setAction((s) => {
        return { ...s, active: true };
      });
    }
  };

  const focusItem = (e) => {
    if (!action.group) {
      setAction((s) => {
        return { ...s, active: false };
      });
      setTimeout(() => {
        if (!checkDrag) {
          e.target.focus();
        }
      }, 100);
    }
  };

  const handleEventEnd = (params) => {
    const { target, isDrag } = params;
    if (isDrag) {
      dispatch(eventEnd(refItems, target.dataset.id));
      setAction((s) => {
        return { ...s, move: false };
      });
    }
  };

  // // 요금 계산
  // useEffect(() => {
  //   if (unitData !== undefined) {
  //     let pr = 0;
  //     let h = (unitData.height * unitData.size).toFixed(0);
  //     let w = (unitData.width * unitData.size).toFixed(0);
  //     fonts.map((v) => {
  //       if (v.name === unitData.font) {
  //         pr = checkLength(unitData.name) * v.price * unitData.scale[0] * unitData.size;
  //         // console.log(pr);
  //       }
  //     });
  //     setPrice({
  //       price: pr,
  //       width: w,
  //       height: h,
  //     });
  //   }
  // }, [data, unitData]);

  // 그룹 가늠자
  const groupRuler = {
    name: "ruler",
    render(moveable) {
      let h = ((groupSize.height / 7) * dynsData[1]?.size + 3).toFixed(0) * 10;
      let w = ((groupSize.width / 7) * dynsData[1]?.size + 3).toFixed(0) * 10;
      return (
        <div
          className={`group_ruler ${dynsData[1]?.backing}`}
          key={0}
          style={{
            width: groupSize.width,
            height: groupSize.height,
            background:
              dynsData[1]?.backing === "hollow_out" || dynsData[1]?.backing === "cut_to_shape"
                ? "transparent"
                : hexToRgba("#" + dynsData[1]?.backingColor, 0.3),
          }}
        >
          <div className="h">
            <span>{comma(h)}mm</span>
          </div>
          <div className="w">
            <span>{comma(w)}mm</span>
          </div>
        </div>
      );
    },
  };

  // 삭제 버튼
  const customAble = {
    name: "remove",
    render(moveable) {
      const { renderPoses } = moveable.state;
      return (
        <button
          onClick={() => {
            setUnitTarget(null);
            dispatch(removeItem(unitData?.id));
          }}
          className="remove-item"
          key={0}
          style={{
            transform: `translate(-50%, -90%) translate(${renderPoses[1][0]}px, ${renderPoses[1][1]}px) translateZ(0px)`,
            zIndex: 100,
          }}
        >
          <i className="icon-delete"></i>
        </button>
      );
    },
  };

  // 대지 클릭 시 타겟 제거
  const focusContent = (e) => {
    if (e.target.classList[0] === "dyns_content") {
      setUnitTarget(null);
      setUnitData(null);
    }
  };

  // 타겟 초기화
  const reset = () => {
    setAction((s) => {
      return { ...s, active: false, neon: false, light: false, ruler: false, group: false, fliker: false };
    });
    setUnitTarget(null);
    setStorage({ data: null, img: null });
    dispatch(getItems());
  };

  const getItem = (data, id) => {
    return data.find((v) => {
      return v.id == id;
    });
  };

  // 다음
  const checkNext = () => {
    let check = true;
    if (dynsData.length === 1) {
      toast.warn("디자인이 비어 있습니다.");
      return false;
    }
    dynsData.map((v, i) => {
      if (i > 0) {
        if (v.type === "text") {
          let name = v.name.replace(/\n/g, "");
          name = name.replace(/\s*/g, "");
          if (name === "") {
            check = false;
          }
        }
      }
    });
    if (!check) {
      toast.warn("디자인이 비어 있습니다.");
      return false;
    }

    // 그룹 사이즈 계산을 위해 설정
    if (dynsData.length > 2)
      setAction((s) => {
        return { ...s, group: true };
      });

    setTimeout(() => {
      setLoader(true);
      setAction((s) => {
        return { ...s, active: false, neon: false, light: false, ruler: false, group: false, fliker: false };
      });
      setUnitTarget(null);
      setTimeout(() => {
        // 이미지 생성
        domtoimage
          .toPng(refContent.current)
          .then(function (dataUrl) {
            navigate("/editor/result", { state: { data: dynsData, img: dataUrl } });
            setLoader(false);
          })
          .catch(function (error) {
            console.error("oops, something went wrong!", error);
          });
      }, 2000);
    }, 0);
  };

  const [targets, setTargets] = useState({});
  useEffect(() => {
    // 나중에 돔 저장소 삭제로직 추가 해야함
    if (dynsData.length > 0) {
      setTimeout(() => {
        for (let box of dynsData) {
          if (box.id !== "new0") {
            const target = document.querySelector(`#${box.id}`);
            targets[box.id] = target;
            setTargets({ ...targets });
          }
        }
      }, 100);
    }
  }, [dynsData]);

  return (
    <>
      <div className="mobile_layout">
        <Slider
          className={`dyns_background ${action.light ? "light" : ""}`}
          asNavFor={refSlider.current[1]}
          ref={(el) => (refSlider.current[0] = el)}
          arrows={false}
          fade={true}
        >
          <div className="bg">
            <div style={{ backgroundImage: `url("/editor/images/m_bgr_thm01.jpg")` }}></div>
          </div>
          <div className="bg">
            <div style={{ backgroundImage: `url("/editor/images/m_bgr_thm02.jpg")` }}></div>
          </div>
          <div className="bg">
            <div style={{ backgroundImage: `url("/editor/images/m_bgr_thm03.jpg")` }}></div>
          </div>
          <div className="bg">
            <div style={{ backgroundImage: `url("/editor/images/m_bgr_thm01.jpg")` }}></div>
          </div>
          <div className="bg">
            <div style={{ backgroundImage: `url("/editor/images/m_bgr_thm02.jpg")` }}></div>
          </div>
          <div className="bg">
            <div style={{ backgroundImage: `url("/editor/images/m_bgr_thm03.jpg")` }}></div>
          </div>
        </Slider>
        <div className="dyns_status">
          <Switch on="ON" off="NEON" name="neon" checked={action} handler={setAction} />
          <Cwitch icon="fliker" name="fliker" checked={action} handler={setAction} disabled={!action.neon} />
          <Cwitch icon="lightbulb" name="light" checked={action} handler={setAction} />
          <Cwitch icon="ruler" name="ruler" checked={action} handler={setAction} disabled={action.group} />
          <Switch on="GRP" off="UNIT" name="group" checked={action} handler={setAction} />
          <button type="button" className="reset" onClick={reset}>
            <i className="icon-reset"></i>
          </button>
        </div>
        <div
          className={`dyns_content mobile_content ${action.ruler ? "ruler" : ""} ${action.group ? "group_content" : ""}`}
          ref={refContent}
          onClick={focusContent}
        >
          <Moveable
            target={groupTarget}
            defaultGroupRotate={0}
            defaultGroupOrigin={"50% 50%"}
            draggable={true}
            throttleDrag={0}
            startDragRotate={0}
            throttleDragRotate={0}
            className="moveable-group"
            zoom={1}
            hideChildMoveableDefaultLines={true}
            origin={false}
            ruler={true}
            ables={[groupRuler]}
            padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
            onDragGroupStart={({ events }) => {
              events.forEach((ev, i) => {
                const idx = i + 1;
                ev.target.style.transform =
                  `translate(${dynsData[idx].translate[0]}px, ${dynsData[idx].translate[1]}px) ` +
                  `rotate(${dynsData[idx].rotate}deg)` +
                  `scale(${dynsData[idx].scale[0]}, ${dynsData[idx].scale[1]})`;
              });
            }}
            onDragGroup={({ events }) => {
              events.forEach((ev, i) => {
                const idx = i + 1;
                dynsData[idx].translate = ev.beforeTranslate;
                ev.target.style.transform =
                  `translate(${ev.beforeTranslate[0]}px, ${ev.beforeTranslate[1]}px) ` +
                  `rotate(${dynsData[idx].rotate}deg)` +
                  `scale(${dynsData[idx].scale[0]}, ${dynsData[idx].scale[1]})`;
              });
              setAction((s) => {
                return { ...s, move: true };
              });
            }}
            onDragGroupEnd={() =>
              setAction((s) => {
                return { ...s, move: false };
              })
            }
          />

          {dynsData.map((v, i) => {
            const item = getItem(dynsData, v.id);
            return (
              <Fragment key={v.id}>
                <Moveable
                  ref={(el) => (refMoveable.current[v.id] = el)}
                  target={targets[v.id]}
                  container={document.getElementById("dyns_content")}
                  flushSync={flushSync}
                  keepRatio={true}
                  origin={false}
                  zoom={1}
                  throttleDrag={0}
                  startDragRotate={0}
                  throttleDragRotate={0}
                  ables={[customAble]}
                  snappable={v.id === unitData?.id}
                  remove={v.id === unitData?.id}
                  className={v.id === unitData?.id ? "moveable-handler" : ""}
                  // 크기
                  scalable={v.id === unitData?.id}
                  throttleScale={0.1}
                  onScale={({ target, scale, drag }) => {
                    if (scale[0] < 1) return;
                    item.scale = scale;
                    item.translate = [drag.beforeTranslate[0], drag.beforeTranslate[1]];
                    target.style.transform =
                      `translate(${item.translate[0]}px, ${item.translate[1]}px) ` + `rotate(${item.rotate}deg)` + `scale(${scale[0]}, ${scale[1]})`;
                  }}
                  // // 회전
                  rotatable={v.id === unitData?.id}
                  throttleRotate={10}
                  rotationPosition="top"
                  onRotate={({ target, beforeRotate }) => {
                    item.rotate = beforeRotate;
                    target.style.transform =
                      `translate(${item.translate[0]}px, ${item.translate[1]}px) ` +
                      `rotate(${item.rotate}deg)` +
                      `scale(${item.scale[0]}, ${item.scale[1]})`;
                  }}
                  // 드레그
                  draggable={true}
                  onDrag={({ target, transform, beforeTranslate }) => {
                    item.translate = [beforeTranslate[0], beforeTranslate[1]];
                    target.style.transform = transform;
                    setCheckDrag(true);
                    setAction((s) => {
                      return { ...s, move: true };
                    });
                  }}
                  onScaleEnd={handleEventEnd}
                  onRotateEnd={handleEventEnd}
                  onDragEnd={handleEventEnd}
                />
                {v.type === "text" ? (
                  <Textarea
                    key={v.id}
                    data={v}
                    isMobile={false}
                    idx={i}
                    activeItem={activeItem}
                    refMoveable={refMoveable}
                    action={action}
                    focusItem={focusItem}
                    refItems={refItems}
                    leng={dynsData.length}
                  />
                ) : (
                  <Svg
                    key={v.id}
                    data={v}
                    isMobile={false}
                    activeItem={activeItem}
                    action={action}
                    focusItem={focusItem}
                    refItems={refItems}
                    leng={dynsData.length}
                  />
                )}
              </Fragment>
            );
          })}
        </div>
        <div className="option_box">
          <ul className={`mobile_tab_box ${action.group ? "disabled" : ""}`}>
            <li
              className={action.unitTab === "text" ? "active" : ""}
              onClick={() =>
                !action.group &&
                setAction((s) => {
                  return { ...s, unitTab: "text" };
                })
              }
            >
              <button type="button">TEXT</button>
            </li>
            <li
              className={action.unitTab === "svg" ? "active" : ""}
              onClick={() =>
                !action.group &&
                setAction((s) => {
                  return { ...s, unitTab: "svg" };
                })
              }
            >
              <button type="button">SVG</button>
            </li>
          </ul>
          <div className="scroll_tab" style={{ display: action.unitTab === "text" ? "block" : "none" }}>
            <ul>
              {mobileMenu.map((v) => {
                if (v === "ADD TEXT") {
                  return (
                    <li key={v}>
                      <button type="button" disabled={action.group} onClick={() => dispatch(addText())}>
                        {v}
                      </button>
                    </li>
                  );
                } else {
                  let classDisabled = "";
                  let disabled = false;
                  if (v === "BACKING" || v === "BACKING COLOR") {
                    if (!action.group && dynsData.length > 2) {
                      classDisabled = "disabled";
                      disabled = true;
                    }
                  } else if (v === "FONT" || v === "COLOR" || v === "SIZE") {
                    if (action.group) {
                      classDisabled = "disabled";
                      disabled = true;
                    }
                  }
                  return (
                    <li key={v}>
                      <button type="button" className={`${v === mobileTab ? "active" : ""} `} disabled={disabled} onClick={() => setMobileTab(v)}>
                        {v}
                      </button>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
          <div className="options_tab" style={{ display: action.unitTab === "text" ? "block" : "none" }}>
            <ul className={`font_group ${mobileTab === "FONT" ? "show" : ""}`}>
              {fonts.map((v) => {
                return (
                  <li key={v.name} className={v.name === unitData?.font ? "active" : ""}>
                    <button
                      type="button"
                      data-font={v.name}
                      onClick={(e) => dispatch(changeFont(e, refItems, unitData?.id))}
                      style={{ fontFamily: `${v.name}` }}
                    >
                      {v.name.replaceAll("_", " ")}
                    </button>
                  </li>
                );
              })}
            </ul>
            <ul className={`color_group ${mobileTab === "COLOR" ? "show" : ""}`}>
              {colors.map((v, i) => {
                return (
                  <li key={i} className={v === unitData?.color ? "active" : ""}>
                    <label className={`el-radio el-inline el-lg color-${v}`}>
                      <input
                        type="radio"
                        name="color"
                        value={`${v}`}
                        checked={v === unitData?.color ? true : false}
                        onChange={(e) => dispatch(changeColor(e, unitData?.id))}
                        disabled={action.group}
                      />
                      <span className="el-radio-style"></span>
                    </label>
                  </li>
                );
              })}
            </ul>
            <ul className={`size_group ${mobileTab === "SIZE" ? "show" : ""}`}>
              {sizes.map((v) => {
                return (
                  <li key={v} className={v == unitData?.size ? "active" : ""}>
                    <button
                      type="button"
                      onClick={() => {
                        dispatch(changeSize(v));
                      }}
                    >
                      {v}x
                    </button>
                  </li>
                );
              })}
            </ul>
            <ul className={`backing_group ${mobileTab === "BACKING" ? "show" : ""}`}>
              {backings.map((v, i) => {
                return (
                  <li key={i} className={v.id === dynsData[1]?.backing ? "active" : ""}>
                    <button
                      type="button"
                      disabled={!action.group && dynsData.length > 2}
                      onClick={() => {
                        dispatch(changeBacking(v.id));
                      }}
                    >
                      {v.name}
                    </button>
                  </li>
                );
              })}
            </ul>
            <ul className={`backing_color_group ${mobileTab === "BACKING COLOR" ? "show" : ""}`}>
              {backingColors.map((v, i) => {
                return (
                  <li key={i} className={v === unitData?.backingColor ? "active" : ""}>
                    <label className={`el-radio el-inline el-lg color-${v}`}>
                      <input
                        type="radio"
                        name="backingColor"
                        value={`${v}`}
                        checked={v === unitData?.backingColor ? true : false}
                        onChange={(e) => dispatch(changeBackingColor(v))}
                        disabled={!action.group && dynsData.length > 2}
                      />
                      <span className="el-radio-style"></span>
                    </label>
                  </li>
                );
              })}
            </ul>
            <div className={`background_group ${mobileTab === "BACKGROUND IMAGE" ? "show" : ""}`}>
              <Slider
                className="thumbs"
                asNavFor={refSlider.current[0]}
                ref={(el) => (refSlider.current[1] = el)}
                infinite={true}
                slidesToShow={1}
                swipeToSlide={true}
                focusOnSelect={true}
                centerMode={true}
                centerPadding="120px"
                arrows={false}
              >
                <div className="thumb">
                  <img src="/editor/images/bgr_thm01@2x.jpg" />
                </div>
                <div className="thumb">
                  <img src="/editor/images/bgr_thm02@2x.jpg" />
                </div>
                <div className="thumb">
                  <img src="/editor/images/bgr_thm03@2x.jpg" />
                </div>
                <div className="thumb">
                  <img src="/editor/images/bgr_thm01@2x.jpg" />
                </div>
                <div className="thumb">
                  <img src="/editor/images/bgr_thm02@2x.jpg" />
                </div>
                <div className="thumb">
                  <img src="/editor/images/bgr_thm03@2x.jpg" />
                </div>
              </Slider>
            </div>
          </div>
          <div className="svg_scroll_tab" style={{ display: action.unitTab === "svg" ? "block" : "none" }}>
            <div className="scroll">
              <ul>
                {[...Array(168).keys()].map((i) => {
                  if (i + 1 === 26) return true;
                  return (
                    <li key={i} onClick={() => dispatch(addSvg(i + 1))}>
                      <div>
                        <i className={`icon-${i + 1}`}></i>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <button type="button" className="next_btn" onClick={checkNext}>
          NEXT
        </button>
        <ToastContainer theme="dark" />
      </div>
    </>
  );
}

export default MobileEditor;
