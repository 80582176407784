import { useState, useEffect } from "react";
import { comma } from "../../common/functions";
import { setName } from "../../modules/dyns";
import { useDispatch } from "react-redux";
import hexToRgba from "hex-to-rgba";

export default function Textarea(props) {
  const { idx, data, activeItem, refMoveable, action, focusItem, refItems, leng } = props;
  const [name, setText] = useState(data.name);
  const dispatch = useDispatch();

  const changeText = (e) => {
    // 첫번째는 빈공간 허용안하고 저장
    setText(e.target.value.substring(0, 1) === " " ? e.target.value.substring(1) : e.target.value);
  };

  useEffect(() => {
    dispatch(setName(refItems, data.id, name));
    refMoveable.current[data.id].updateRect();
  }, [name, data.font]);

  // 초기 포지션
  const styles = {
    transform: `translate(${data.translate[0]}px, ${data.translate[1]}px) rotate(${data.rotate}deg) scale(${data.scale[0]}, ${data.scale[1]})`,
    background:
      data.backing === "hollow_out" || data.backing === "cut_to_shape" || leng > 2 ? "transparent" : hexToRgba("#" + data.backingColor, 0.3),
  };

  return (
    // Uncaught TypeError: Cannot set properties of null (setting 'dist') 에러로 temp 데이터 생성해서 이벤트는 등록하되 화면에 안보이게 처리
    <div
      className={`item_wrap ${idx === 0 ? "temp" : "unit"} ${action.neon && !action.move ? "neon" : ""} c-${data.color}`}
      data-id={data.id}
      onMouseDown={activeItem}
      onMouseUp={focusItem}
      onTouchStart={activeItem}
      onTouchEnd={focusItem}
      style={styles}
      id={data.id}
      ref={(e) => (refItems.current[data.id] = e)}
    >
      <div className="h">
        <span>{comma((data.height * data.size).toFixed(0))}mm</span>
      </div>
      <div className="w">
        <span>{comma((data.width * data.size).toFixed(0))}mm</span>
      </div>
      <div className={`item ${action.fliker ? "fliker" : ""}`}>
        <textarea
          value={name}
          onChange={changeText}
          spellCheck="false"
          // disabled={action.active}
          style={{
            color: `#${data.color}`,
            fontFamily: `${data.font}`,
          }}
        ></textarea>
        <div
          className="sync"
          style={{
            fontFamily: `${data.font}`,
          }}
        >
          {/* 엔터처리 */}
          {name.length > 0
            ? name.split("\n").map((line, key) => {
                // 공백처리
                if (line.split(" ").length > 1) {
                  line = line.split(" ").map((line2, key2) => {
                    if (key2 > 0) {
                      return <span key={key2}>&nbsp;{line2}</span>;
                    } else {
                      return <span key={key2}>{line2}</span>;
                    }
                  });
                }
                if (name.split("\n").length > 1) {
                  return (
                    <span key={key}>
                      {line}
                      <br />
                    </span>
                  );
                } else {
                  return line;
                }
              })
            : "T"}
        </div>
      </div>
    </div>
  );
}
