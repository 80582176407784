import { useState, useEffect } from "react";
import { comma } from "../../common/functions";
import { setName } from "../../modules/dyns";
import { useDispatch } from "react-redux";

export default function Svg(props) {
  const { idx, data, activeItem, focusItem, refItems, action } = props;
  const dispatch = useDispatch();

  const styles = {
    transform: `translate(${data.translate[0]}px, ${data.translate[1]}px) rotate(${data.rotate}deg) scale(${data.scale[0]}, ${data.scale[1]})`,
  };

  return (
    // Uncaught TypeError: Cannot set properties of null (setting 'dist') 에러로 temp 데이터 생성해서 이벤트는 등록하되 화면에 안보이게 처리
    <div
      className={`item_wrap ${idx === 0 ? "temp" : "unit"} ${action.neon && !action.move ? "neon" : ""} c-${data.color}`}
      data-id={data.id}
      onMouseDown={activeItem}
      onMouseUp={focusItem}
      onTouchStart={activeItem}
      onTouchEnd={focusItem}
      style={styles}
      id={data.id}
      ref={(e) => (refItems.current[data.id] = e)}
    >
      <div className="h">
        <span>{comma((data.height * data.size).toFixed(0))}mm</span>
      </div>
      <div className="w">
        <span>{comma((data.width * data.size).toFixed(0))}mm</span>
      </div>
      <div className={`item ${action.fliker ? "fliker" : ""}`}>
        <i
          className={`svg icon-${data.name}`}
          style={{
            color: `#${data.color}`,
          }}
        ></i>
      </div>
    </div>
  );
}
