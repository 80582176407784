import { adapter, agree1, agree2 } from "../../common/constant";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { changeLocation, setItems, changeAdapter } from "../../modules/dyns";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { format } from "date-fns";
import * as api from "../../api/dyns";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { validateEmail, onlyNumber } from "../../common/functions";

function Result(props) {
  const { setLoader } = props;
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { dyns } = useSelector((state) => state);
  const [customer, setCustomer] = useState({ name: "", phone: "", email: "", agree1: false, agree2: false });
  const [storage, setStorage] = useLocalStorage("dyns", { data: null, img: null });
  const [sendDisabled, setSendDisabled] = useState(false);
  const { data } = dyns;
  const navigate = useNavigate();

  useEffect(() => {
    // 스토리지 정보가 없으면 넘어온 데이터로 디스패치
    if (storage.data === null) {
      dispatch(setItems(state.data));
    } else {
      dispatch(setItems(storage.data));
    }
  }, []);

  // 스토리지에 실시간 업데이트
  useEffect(() => {
    setStorage({ data: data, img: state.img });
  }, [data]);

  const changeCustomer = (e) => {
    let value = e.target.value;
    if (e.target.name === "phone") {
      value = onlyNumber(e.target.value);
    }
    setCustomer({
      ...customer,
      [e.target.name]: e.target.name === "agree1" || e.target.name === "agree2" ? !customer[e.target.name] : value,
    });
  };
  const sendEmail = async () => {
    setSendDisabled(true);
    if (customer.name === "") {
      toast.error("성함을 입력해주세요.");
      document.querySelector("#name").focus();
      setSendDisabled(false);
      return false;
    }
    if (customer.phone === "") {
      toast.error("전화번호를 입력해주세요.");
      document.querySelector("#phone").focus();
      setSendDisabled(false);
      return false;
    }
    if (!validateEmail(customer.email)) {
      toast.error("이메일을 확인해주세요.");
      document.querySelector("#email").focus();
      setSendDisabled(false);
      return false;
    }
    if (!customer.agree1) {
      toast.error("개인정보 수집 및 이용안내에 동의 해주세요.");
      setSendDisabled(false);
      return false;
    }
    if (!customer.agree2) {
      toast.error("개인정보 제 3자 제공내역에 동의 해주세요.");
      setSendDisabled(false);
      return false;
    }
    if (!window.confirm("해당 내용으로 신청하시겠습니까?")) {
      setSendDisabled(false);
      return false;
    }
    setLoader(true);
    try {
      const result = await api.apiSendEmail(data, state.img, customer);
      setLoader(false);
      if (result.status === 200) {
        if (result.data.state === "ok") {
          alert(result.data.msg);
          setStorage({ data: null, img: null });
          navigate("/editor/");
        } else {
          toast.error(result.data.msg);
        }
        setSendDisabled(false);
      } else {
        toast.warn("시스템 통신 오류로 신청이 정상적으로 완료되지 않았습니다.\n다시시도해주세요.");
        setSendDisabled(false);
      }
    } catch (e) {
      setLoader(false);
      setSendDisabled(false);
      console.log(e);
    }
  };

  return (
    <div className="syns_layout result_layout">
      <div className="right result">
        <div className="result_box">
          <div className="thumb">
            <div className="thumb_wrap">
              <div className="img_box" style={{ backgroundImage: `url( ${state.img} )` }}></div>
              <button
                type="button"
                className="btn btn-block btn-outline-primary"
                onClick={() => saveAs(state.img, `chamelneon_dyns${format(new Date(), "yyyyMMddssSSS")}.png`)}
              >
                DOWNLOAD
              </button>
            </div>
          </div>
          <div className="dyns_options">
            <div className="label-group">
              <label htmlFor="">LOCATION</label>
              <div className="location">
                <ul className="grid-row">
                  <li className="grid-col">
                    <label className="btn-radio">
                      <input
                        type="radio"
                        name="location"
                        value="indoors"
                        checked={data[0]?.location === "indoors"}
                        onChange={(e) => dispatch(changeLocation(e))}
                      />
                      <span className="btn btn-style">INDOORS</span>
                    </label>
                  </li>
                  <li className="grid-col">
                    <label className="btn-radio">
                      <input
                        type="radio"
                        name="location"
                        value="outdoors"
                        checked={data[0]?.location === "outdoors"}
                        onChange={(e) => dispatch(changeLocation(e))}
                      />
                      <span className="btn btn-style">OUTDOORS</span>
                    </label>
                    <span className="add">+10%</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="label-group label-group-column">
              <label htmlFor="">POWER ADAPTER OPTION</label>
              <div className="adapter">
                {adapter.map((v, i) => {
                  return (
                    <label className="btn-radio" key={i}>
                      <input type="radio" name="adapter" value={v} checked={data[0]?.adapter === v} onChange={(e) => dispatch(changeAdapter(e))} />
                      <span className="btn btn-style">{v}</span>
                    </label>
                  );
                })}
              </div>
            </div>
            <button type="button" className="back_btn" onClick={() => navigate(-1)}>
              <i className="icon-edit"></i> BACK TO EDIT
            </button>
          </div>
        </div>
        <div className="customer">
          <h1>나만의 네온사인 만들기 이벤트 신청</h1>
          <div className="label-group">
            <label htmlFor="">NAME</label>
            <input type="text" name="name" className="form-control" id="name" value={customer.name} onChange={(e) => changeCustomer(e)} />
          </div>
          <div className="label-group">
            <label htmlFor="">PHONE</label>
            <input type="text" name="phone" className="form-control" id="phone" value={customer.phone} onChange={(e) => changeCustomer(e)} />
          </div>
          <div className="label-group">
            <label htmlFor="">EMAIL</label>
            <input
              type="email"
              name="email"
              className="form-control"
              id="email"
              value={customer.email}
              onChange={(e) => changeCustomer(e)}
              placeholder="your@email.com"
            />
          </div>
        </div>
        <div className="agree_box">
          <pre className="agree">{agree1}</pre>
          <label className="el-checkbox el-inline">
            <input type="checkbox" name="agree1" checked={customer.agree1} onChange={(e) => changeCustomer(e)} />
            <span className="el-checkbox-style"></span>
            <span className="el-label"> 개인정보 수집 및 이용안내</span>
          </label>
          <br />
          <br />
          <pre className="agree">{agree2}</pre>
          <label className="el-checkbox el-inline">
            <input type="checkbox" name="agree2" checked={customer.agree2} onChange={(e) => changeCustomer(e)} />
            <span className="el-checkbox-style"></span>
            <span className="el-label"> 개인정보 제 3자 제공내역</span>
          </label>
        </div>

        <button type="button" className="next_btn" onClick={sendEmail} disabled={sendDisabled}>
          Send Neon
        </button>
      </div>
      <ToastContainer theme="dark" />
    </div>
  );
}

export default Result;
