export function focusOut(ref, callback) {
  ref.current.addEventListener("focusout", callback);
}

export function comma(number) {
  return number.toLocaleString();
}

export function uncomma(string) {
  const number = String(string);
  const result = parseInt(number.replace(/[^\d]+/g, ""));
  return isNaN(result) ? 0 : result;
}

export function validateEmail(inputText) {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (inputText.match(mailformat)) {
    return true;
  } else {
    return false;
  }
}

export function onlyNumber(value) {
  return value.replace(/[^0-9]/g, "");
}

export function checkLength(str) {
  const redex = /\s/gi;
  return str.toString().replace(redex, "").length;
}
