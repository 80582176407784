// 색상
export const colors = ["fd8e01", "ffd203", "fec657", "00bcec", "0000fe", "ff0e1c", "fe00dc", "ffffff", "c401ab", "00e847"];
export const backingColors = ["000000", "fe00dc", "ffffff", "222222", "868686", "fefe00"];
export const sizes = [1, 2, 3, 4];
export const adapter = ["USA", "UK", "AZ", "KOR", "JPN"];
export const mobileMenu = ["ADD TEXT", "FONT", "COLOR", "SIZE", "BACKING", "BACKING COLOR", "BACKGROUND IMAGE"];
export const backings = [
  {
    id: "hollow_out",
    name: "HOLLOW OUT",
  },
  {
    id: "cut_to_shape",
    name: "CUT TO SHAPE",
  },
  {
    id: "full_board",
    name: "FULL BOARD",
  },
  {
    id: "stand",
    name: "STAND",
  },
];
// 폰트
export const fonts = [
  {
    name: "DINSKI_CASUAL_CONDENSED",
    width: 10,
    height: 30,
    price: 7000,
  },
  {
    name: "FAD_SCRIPT",
    width: 10,
    height: 14,
    price: 10000,
  },
  {
    name: "HELVETICA",
    width: 10,
    height: 14,
    price: 10000,
  },
  {
    name: "LANENAR",
    width: 10,
    height: 14,
    price: 10000,
  },
  {
    name: "MGENPLUS",
    width: 10,
    height: 14,
    price: 10000,
  },
  {
    name: "NAGOYA",
    width: 10,
    height: 14,
    price: 10000,
  },
  {
    name: "ROUNDED_MPLUS",
    width: 10,
    height: 14,
    price: 10000,
  },
  {
    name: "SQUIB",
    width: 10,
    height: 14,
    price: 10000,
  },
  {
    name: "SUNSET_CITY",
    width: 10,
    height: 14,
    price: 10000,
  },
];

// 기본값
export const defaultUnit = [
  {
    id: "new0",
    target: null,
    type: "text",
    font: "DINSKI_CASUAL_CONDENSED",
    name: "TEMP",
    translate: [0, 0],
    scale: [1, 1],
    rotate: 0,
    color: "fd8e01",
    backing: "hollow_out",
    backingColor: "000000",
    width: 310,
    height: 100,
    groupWidth: 0,
    groupHeight: 0,
    size: 1,
    location: "indoors",
    adapter: "USA",
  },
  {
    id: "new1",
    target: null,
    type: "text",
    font: "DINSKI_CASUAL_CONDENSED",
    name: "chamelneon",
    translate: [0, 0],
    scale: [1, 1],
    rotate: 0,
    color: "fd8e01",
    backing: "hollow_out",
    backingColor: "000000",
    width: 310,
    height: 100,
    groupWidth: 0,
    groupHeight: 0,
    size: 1,
    location: "indoors",
    adapter: "USA",
  },
];

export const agree1 = `
G-FAIR KOREA 2022의  전시 기업인 (주) 디플럭스는 
고객 계약, 구매 , 주문 상담의  관리 및 안내를 위하여 
아래와 같이 개인정보를 수집 · 이용 및 제 3자에게 제공하고자 합니다 
내용을 자세히 읽으신 후 동의 여부를 결정하여 주십시오.

개인정보 수집 · 이용 내역

항목 
성명, 휴대전화, 이메일   

수집 이용 목적  
상담, 계약, 주문 및  안내

보유, 이용 기간 
박람회 참가 및 문의 구매, 주문 기록 : 1년

※ 위의 개인정보 수집 · 이용에 대한 동의를 거부할 권리가 있습니다. 
그러나, 동의를 거부할 경우  상담, 주문, 구매 등의 완결에 제한을 받을 수 있습니다.

위와 같이 개인정보를 수집 · 이용하는데 동의하십니까?
`;
export const agree2 = `
항목 
제품 관련 협력 업체, 배송, 물류 업체 

제공목적
제품 제작 및 배송 

제공항목
성명, 휴대전화, 이메일  

보유 · 이용 기간
성명, 휴대전화, 이메일 등   1년

※ ※ 위의 개인정보 수집 · 이용에 대한 동의를 거부할 권리가 있습니다. 
그러나, 동의를 거부할 경우  상담, 주문, 구매 등의  완결에 제한을 받을 수 있습니다.

위와 같이 개인정보를 수집 · 이용하는데 동의하십니까?
`;
