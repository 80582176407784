import { Route, Routes } from "react-router-dom";
import Editor from "./components/page/Editor";
import MobileEditor from "./components/page/MobileEditor";
import Result from "./components/page/Result";
import { Grid } from "react-loader-spinner";
import { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";

function App() {
  const [loader, setLoader] = useState(false);

  return (
    <div className="App">
      <BrowserView>
        <Routes>
          <Route exact path="/editor" element={<Editor setLoader={setLoader} />} />
          <Route path="/editor/result" element={<Result setLoader={setLoader} />} />
        </Routes>
      </BrowserView>
      <MobileView>
        <Routes>
          <Route exact path="/editor" element={<MobileEditor setLoader={setLoader} />} />
          <Route path="/editor/result" element={<Result setLoader={setLoader} />} />
        </Routes>
      </MobileView>
      <div className={`page_loader ${loader ? "loading" : ""}`}>
        <Grid height="100" width="100" color="#78ff11" ariaLabel="grid-loading" radius="12.5" wrapperStyle={{}} wrapperClass="" visible={true} />
      </div>
    </div>
  );
}

export default App;
